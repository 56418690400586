// @flow
// A context simply to prove that the canvas is in fullscreen mode or not.
import React, { createContext, useContext, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import type { Map } from '@mission.io/mission-toolkit'
import { useContextualData, type ContextualDataEntity } from '../../store/selectors/selectorHooks'
import { useOnClickOutside } from '../../utility/hooks.jsx'
import {
	InformationFrame,
	ExtenderFrame,
} from '../LiteracyEvent/displays/LiteracyEventFrame/InformationFrame/index.jsx'
import { Voting } from './Voting.jsx'
import { TextEventInput, MediaEventInput } from '../LiteracyEvent/helperComponents/index.js'
import { MdClose } from 'react-icons/md'

export type ContextualDataLookup =
	| {
			type: 'CREATIVE_CANVAS',
			canvasActionId: string,
			maps: Map[],
			entity?: ContextualDataEntity,
			index: number,
	  }
	| {
			type: 'MAP',
			map: Map,
			includeUnscannedObjects: boolean,
			index: number,
	  }

const ExpandedDataContext = createContext({
	openExpandedData: (params: { lookup: ContextualDataLookup, allowVote: boolean } | null) => {},
})

// These variables exist so that expanded images can be rendered in the correct location for contextual data views.
export const EXPANDED_DATA_READER_PARENT_ID = 'EXPANDED_DATA_READER_PARENT' // This is the parent element that the modal will be rendered in.
export const EXPANDED_DATA_READER_ID = 'EXPANDED_DATA_READER' // This is the element that the modal will be rendered in.

export const ExpandedDataProvider = ({ children }: { children: React$Node }): React$Node => {
	const [expandedData, setExpandedData] = useState<{
		lookup: ContextualDataLookup,
		allowVote: boolean,
	} | null>(null)

	const contextualDataLookup = expandedData?.lookup

	return (
		<ExpandedDataContext.Provider value={{ openExpandedData: setExpandedData }}>
			{children}
			{contextualDataLookup && (
				<ExpandedDataView
					allowVote={expandedData?.allowVote}
					lookup={contextualDataLookup}
					onFinishClose={() => setExpandedData(null)}
				/>
			)}
		</ExpandedDataContext.Provider>
	)
}

export const useOpenExpandedData: () => ({
	lookup: ContextualDataLookup,
	allowVote: boolean,
}) => void = () => {
	return useContext(ExpandedDataContext)?.openExpandedData
}

/**
 * A component that displays the full contextual data for a single piece of contextual data. It includes the title, subtitle, text, and media, and the ability to vote on the data. It
 * is made up of sub components from the literacy event component so as to look and feel the same as the literacy event.
 * @param {{}} props
 * @param {StudentSpecificContextualDataEntry} props.contextualData - The contextual data to display
 * @param {() => void} props.onFinishClose - A function to call when the frame finishes closing
 * @param {((vote: number, location: { x: number, y: number }) => mixed) | null} props.onVote - A function to call when the user votes on the importance
 */
export function ExpandedDataView({
	lookup,
	onFinishClose,
	allowVote = true,
}: {
	lookup: ContextualDataLookup,
	onFinishClose: () => void,
	allowVote?: boolean,
}): React$Node {
	const dispatch = useDispatch()
	const params =
		lookup.type === 'CREATIVE_CANVAS'
			? [
					{ type: 'CREATIVE_CANVAS', canvasActionId: lookup.canvasActionId, maps: lookup.maps },
					lookup.entity,
			  ]
			: [{ type: 'MAP', map: lookup.map, includeUnscannedObjects: true }]
	const contextualData = useContextualData(...params)[lookup.index]

	const onVote = (vote: number, location: { x: number, y: number }) => {
		dispatch(
			sendMessage(
				'CONTEXTUAL_DATA_VOTE',
				{
					dataId: contextualData.id,
					vote,
					dataSource: contextualData.dataSource,
				},
				{ location }
			)
		)
	}

	const media = contextualData?.media?.length > 0 ? contextualData?.media[0] : null
	const [animateClose, setAnimateClose] = useState(false)
	const [isMediaOpen, setIsMediaOpen] = useState(false)

	const extenderRef = useRef<?HTMLDivElement>()
	let ref: ?{ current: ?HTMLElement }
	const startClose = React.useCallback(
		(node, force?: boolean) => {
			// Force should be true if user is clicking "Exit" within the modal
			if (force) {
				setAnimateClose(true)
				return
			}
			// Check if the clicked on element has the class Media-Modal-overlay
			if (isMediaOpen) {
				return
			}

			if (ref?.current?.contains(node) || extenderRef.current?.contains(node)) {
				return
			}
			setAnimateClose(true)
		},
		[isMediaOpen, ref]
	)

	const onlyMedia = media && !contextualData.text.length
	ref = useOnClickOutside(startClose)
	const controlModal = {
		isOpen: isMediaOpen,
		setIsOpen: setIsMediaOpen,
	}

	return (
		contextualData && (
			<div
				className="absolute bg-black/75 w-full h-full top-0 left-0 z-[1]"
				id={EXPANDED_DATA_READER_PARENT_ID}>
				<div className="relative w-full h-full md:h-3/5 flex p-10" id={EXPANDED_DATA_READER_ID}>
					<InformationFrame
						contentClassName="flex flex-col"
						isExiting={animateClose}
						onFinishExit={onFinishClose}
						ref={ref}
						hideDecal>
						{!onlyMedia ? (
							<>
								<div className="flex w-full justify-between pt-2 px-4">
									<h3 className="text-black">{contextualData.title}</h3>
									<div className="flex space-x-4 self-start">
										{allowVote && (
											<Voting
												score={contextualData.classVote}
												vote={contextualData.studentVote}
												onSelect={onVote}
											/>
										)}
										<button
											onClick={e => startClose(e, true)}
											className="bg-error hover:bg-error/75 cursor-pointer uppercase font-game-headline text-sm text-white rounded-lg px-2 flex align-center gap-2 !w-fit">
											<MdClose />
											Exit
										</button>
									</div>
								</div>
								<div className="prose px-4 font-bold">{contextualData.subtitle}</div>
								<TextEventInput
									text={contextualData.text}
									className="flex-1 overflow-auto block h-auto"
								/>
							</>
						) : (
							<>
								<div className="flex w-full justify-between pt-2 px-4">
									<h3 className="text-black">{contextualData.title}</h3>
									<div className="flex space-x-4 self-start">
										{onVote && (
											<Voting
												score={contextualData.classVote}
												vote={contextualData.studentVote}
												onSelect={onVote}
											/>
										)}
										<button
											onClick={e => startClose(e, true)}
											className="bg-error hover:bg-error/75 cursor-pointer uppercase font-game-headline text-sm text-white rounded-lg px-2 flex align-center gap-2 !w-fit">
											<MdClose />
											Exit
										</button>
									</div>
								</div>
								<div className="prose px-4 font-bold">{contextualData.subtitle}</div>
								<MediaEventInput
									media={contextualData.media[0]}
									className="items-start pl-2"
									controlModal={controlModal}
								/>
							</>
						)}
					</InformationFrame>
					{media && !onlyMedia && (
						<ExtenderFrame
							isExiting={animateClose}
							ref={extenderRef}
							media={media}
							controlModal={controlModal}
						/>
					)}
				</div>
			</div>
		)
	)
}
