// @flow
import React, { type AbstractComponent } from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { InfiniteMarquee } from './InfiniteMarquee'
import { DecalInformation } from './DecalInformation'
import { DecalExtenderFrame } from './DecalExtenderFrame'
import { fadeIn, fadeOut } from '../../../helperComponents/keyframes'
import { VideoController } from '../../../../VideoController.jsx'
import { isController } from '../../../../AppSetup.jsx'
import classnames from 'classnames'
import Loading from '../../../../basics/Loading.jsx'
import { ContentExpander } from '../../../helperComponents/ContentExpander.jsx'
import type { Image, Video, Audio } from '@mission.io/mission-toolkit/actions'

type InformationFrameProps = {
	className?: string,
	contentClassName?: string,
	hideDecal?: boolean,
	children: React$Node,
	headline?: string | null,
	isExiting: boolean,
	onFinishExit: () => void,
}
/**
 * A frame for displaying information about a literacy event.
 */
export const InformationFrame: AbstractComponent<
	InformationFrameProps,
	?HTMLDivElement
> = React.forwardRef(function _InformationFrame(
	{
		className,
		children,
		headline,
		isExiting,
		onFinishExit,
		hideDecal,
		contentClassName,
	}: InformationFrameProps,
	ref
): React$Node {
	const id = React.useId()
	return (
		<InformationFrameStyle
			data-testid="information-frame"
			ref={ref}
			className={className}
			$exit={isExiting}
			id={id}
			onAnimationEnd={e => {
				if (e.target.id === id) {
					isExiting && onFinishExit()
				}
			}}>
			<AnimatedFade $exit={isExiting}>
				{headline && <InfiniteMarquee title={headline}></InfiniteMarquee>}
				<InformationContent className={contentClassName}>{children}</InformationContent>
				{!hideDecal && <DecalInformation />}
			</AnimatedFade>
		</InformationFrameStyle>
	)
})

export type ExtenderMedia = Image | Video | Audio
type ExtenderFrameProps = {
	style?: {},
	media: ExtenderMedia,
	isExiting: boolean,
	controlModal?: { isOpen: boolean, setIsOpen: Function },
}

/**
 * An extended portion of the frame that can be used to display additional information.
 */
export const ExtenderFrame: AbstractComponent<
	ExtenderFrameProps,
	?HTMLDivElement
> = React.forwardRef(function _ExtenderFrame(
	{ style, media, isExiting, controlModal }: ExtenderFrameProps,
	ref
): React$Node {
	const positioningRef = React.useRef()
	const [isLoadFinished, setIsLoadFinished] = React.useState(false)
	return (
		<div css="position: relative; flex-grow:1; ">
			<ExtenderFrameStyle
				ref={ref}
				style={style}
				$exit={isExiting}
				className="absolute z-0 left-full opacity-0 top-1/2 -translate-y-1/2 w-full ">
				<div className="flex items-center">
					{isLoadFinished && <DecalExtenderFrame contentRef={positioningRef} />}
					<div
						// The left margin moves it over so that the media inside does not get cut off as much. This is not a perfect solution, as the image still gets cuts off differently at different screen sizes.
						className="relative bg-[rgba(0,255,255)]/50 border-[3px] border-[--color-accent-aqua] ml-[1px] rounded-r-[1rem] p-3 pl-0"
						ref={positioningRef}>
						<ExtendedContent
							media={media}
							onLoadFinished={() => {
								setIsLoadFinished(true)
							}}
							controlModal={controlModal}
						/>
					</div>
				</div>
			</ExtenderFrameStyle>
		</div>
	)
})

/**
 * The content for a smaller frame frame that will roll out from the side of the literacy event frame. For most text literacy events, this will be an image.
 * @param {Image|Video|Audio} props.media - The media to display in the extended content
 * @param {{isOpen: boolean, setIsOpen: Function}} props.controlModal - A function to control the expanded modal for a media item
 * @param {Function} props.onLoadFinished - A function to call when the media has finished loading
 * @returns {React$Node}
 */
function ExtendedContent({
	media,
	controlModal,
	onLoadFinished,
}: {
	media: ExtenderMedia,
	controlModal?: { isOpen: boolean, setIsOpen: Function },
	onLoadFinished: () => void,
}): React$Node {
	const [loaded, _setLoaded] = React.useState(false)
	const setLoaded = (value: boolean) => {
		_setLoaded(value)
		if (onLoadFinished) onLoadFinished()
	}

	const mediaJsx =
		media.type === 'IMAGE' ? (
			<img
				alt="Event Alert"
				className="overflow-hidden border-2 border-[--color-accent-aqua]"
				src={media.url}
				onLoad={() => setLoaded(true)}
			/>
		) : media.type === 'VIDEO' ? (
			<div
				className={
					'max-h-full max-w-full min-h-36 aspect-video bg-black rounded-r-xl overflow-hidden'
				}>
				<VideoController autoPlay={false} src={media.url} onLoad={() => setLoaded(true)} />
			</div>
		) : (
			<audio onLoadedData={() => setLoaded(true)} className="pl-1 min-w-36 max-w-full" controls>
				<source src={media.url} />
			</audio>
		)
	// The max media height allowed differs between the controller and student view
	const maxHeight = isController()
		? '[&>img]:max-h-[calc(23vh-48px)]'
		: '[&>img]:max-h-[calc(35vh-48px)]'
	return (
		<>
			<div
				className={classnames(
					maxHeight,
					'[&>img]:rounded-r-xl [&>img]:border-l-0 relative rounded-r-xl '
				)}>
				{!loaded && (
					<div className="absolute top-0 h-full w-full">
						<Loading className="size-8 inset-center" />
					</div>
				)}
				{mediaJsx}
			</div>
			{media.type !== 'AUDIO' && loaded && (
				<ContentExpander className="bg-[#020223] bottom-8 right-8" controlModal={controlModal}>
					{mediaJsx}
				</ContentExpander>
			)}
		</>
	)
}

const VERTICAL_SLIDE_IN_DURATION = 250
const CONTENT_FADE_IN_DURATION = 250
const EXTENDER_SLIDE_IN_DURATION = 250
export const TOTAL_DELAY: number =
	VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION + EXTENDER_SLIDE_IN_DURATION

const slideDown = keyframes`
from {
	height: 0%;
}
to {
	height: 100%;
}
`
const slideUp = keyframes`
	from {
		height: 100%;	

	}
	to {
		height: 0%;
	}
`

const InformationFrameStyle = styled.div.attrs(() => ({ className: 'bg-primary-600' }))`
	font-size: var(--font-size-m);
	width: 70%;
	z-index: 1;
	${({ $exit }) => css`
		animation: ${$exit ? slideUp : slideDown} ${VERTICAL_SLIDE_IN_DURATION}ms ease-in
			${$exit ? CONTENT_FADE_IN_DURATION : 0}ms 1 forwards;
	`}

	border-radius: 1rem;
	padding: 8px;
	position: relative;
`
const AnimatedFade = styled.div`
	${({ $exit }) => css`
		opacity: ${$exit ? 1 : 0};
		animation: ${$exit ? fadeOut : fadeIn} ${CONTENT_FADE_IN_DURATION}ms ease-in
			${$exit ? 0 : VERTICAL_SLIDE_IN_DURATION}ms 1 forwards;
	`}
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const InformationContent = styled.div`
	flex: 1;
	overflow-y: hidden;
	background: linear-gradient(#ddddf6, #c4feff);
	border-radius: 1rem;
	border: 4px solid var(--color-accent-aqua);
	text-align: left;
`

const slideIn = keyframes`
from {
	left: -100%;
}
	to {
		left: 0%;
	}
`
const slideOut = keyframes`
	from {
		left: 0%;
	}
	to {
		left: -100%;
	}
`

const ExtenderFrameStyle = styled.div`
	${({ $exit }) =>
		$exit
			? css`
					opacity: 1;
					left: 0%;
					animation: ${slideOut} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards 0ms,
						${fadeOut} 1ms linear forwards ${EXTENDER_SLIDE_IN_DURATION}ms;
			  `
			: css`
					opacity: 0;
					left: -100%;
					animation: ${fadeIn} 1ms linear forwards
							${VERTICAL_SLIDE_IN_DURATION + 0.5 * CONTENT_FADE_IN_DURATION}ms,
						${slideIn} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards
							${VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION}ms;
			  `}
`
