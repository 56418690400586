import React, { createContext, useContext } from 'react'
const CanvasFullscreenTeacherViewContext = createContext()

/**
 * React context allowing children to know if the canvas is in fullscreen mode or not.
 * @param {children} param0
 * @returns
 */
export const CanvasFullscreenTeacherViewProvider = ({
	children,
	isFullscreen,
}: {
	children: React$Node,
	isFullscreen: boolean,
}): React$Node => {
	return (
		<CanvasFullscreenTeacherViewContext.Provider value={{ isFullscreen }}>
			{children}
		</CanvasFullscreenTeacherViewContext.Provider>
	)
}

export const useCanvasFullscreenTeacherView: () => boolean = () => {
	return Boolean(useContext(CanvasFullscreenTeacherViewContext)?.isFullscreen)
}
