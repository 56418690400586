import React from 'react'
import classnames from 'classnames'
import { TbArrowBigUp, TbArrowBigDown } from 'react-icons/tb'

/**
 * A tag component that allows the user to upvote or downvote.
 * The user may upvote until their applied score is 3 and downvote until their applied score is -3.
 * The total score is displayed in the middle of the tag.
 */
export function Voting({
	className,
	vote: _vote,
	onSelect,
	score,
}: {
	className?: string,
	vote: number | null,
	onSelect: (vote: number, location: { x: number, y: number }) => mixed,
	score: number,
}): React$Node {
	const vote = _vote ?? 0
	const upVote = e => {
		if (vote >= 3) {
			return
		}
		const parentRect = e.target.parentElement.getBoundingClientRect()
		onSelect(vote + 1, { x: parentRect.x - 24, y: parentRect.y })
		e.stopPropagation()
		e.preventDefault()
	}

	const downVote = e => {
		if (vote <= -3) {
			return
		}
		const parentRect = e.target.parentElement.getBoundingClientRect()
		onSelect(vote - 1, {
			x: parentRect.x + parentRect.width + 4,
			y: parentRect.y + parentRect.height,
		})
		e.stopPropagation()
		e.preventDefault()
	}

	return (
		<div
			className={classnames(
				className,
				_vote === null
					? 'animate-pulse-shadow [--pulse-shadow-from:theme(colors.primary.800/70%)] [--pulse-shadow-to:theme(colors.primary.800/0%)]'
					: '',
				' bg-primary-800/50 text-white gap-2 flex px-2 items-center rounded-full w-fit'
			)}>
			<button
				title={`Upvote`}
				onClick={upVote}
				disabled={vote >= 3}
				className="cursor-pointer text-accent-green hover:opacity-50">
				<TbArrowBigUp className="transition-opacity" style={{ opacity: vote >= 3 ? 0 : 1 }} />
			</button>
			{score}
			<button
				title="Downvote"
				onClick={downVote}
				disabled={vote <= -3}
				className="cursor-pointer text-error hover:brightness-75">
				<TbArrowBigDown className="transition-opacity" style={{ opacity: vote <= -3 ? 0 : 1 }} />
			</button>
		</div>
	)
}
