// @flow
import React from 'react'
import { useState } from 'react'
import Rubric from '../CreativeCanvas/components/Rubric'
import { DataDisplay, useDoesDataExistForEntity } from '../CreativeCanvas/components/DataDisplay'
import { FaCheck, FaStar } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { getIssueContent } from '../CreativeCanvas/connect/selectors'
import { getCollaborativeCulminatingMomentScreen } from '../../store/stores/general'
import { isController } from '../AppSetup'
import { COLLABORATIVE_CULMINATING_MOMENT, TRAINING } from '@mission.io/mission-toolkit/constants'
import type { MediaModalOverrides } from '../ContextualData/ContextualDataCard'
import { getMyTeam } from '../../store/selectors/jrPlusState/teams'
import classnames from 'classnames'

const AVAILABLE_TABS = {
	RUBRIC: 'RUBRIC',
	DATA: 'DATA',
}

/**
 * Tabs - shows tabs for rubric and canvas data for the given canvasId
 *
 * @param {Object} props - the react props
 * @param {string} props.canvasId - the id of the canvas station to display tabs for
 * @param {string} props.teamId - the id of the team to show the tabs for
 * @param {?string} props.document? - the id of the document to show tabs for
 * @param props.shouldUseContextualDataForTeam if true, the contextual data shown will be the data for the team specified in `props.teamId` instead of the current user
 *
 * @return {React$Node}
 */
export default function Tabs({
	canvasId,
	teamId,
	documentId,
	className,
	mediaModalOverrides,
	shouldUseContextualDataForTeam,
}: {
	canvasId: string,
	teamId: string,
	documentId?: ?string,
	className?: string,
	mediaModalOverrides?: ?MediaModalOverrides,
	shouldUseContextualDataForTeam?: boolean,
}): React$Node {
	const [selectedTab, setSelectedTab] = useState<$Keys<typeof AVAILABLE_TABS>>(
		AVAILABLE_TABS.RUBRIC
	)
	const { criteria } = useSelector(getIssueContent({ canvasId, teamId })) || {}
	const collaborativeCulminatingMomentScreen = useSelector(getCollaborativeCulminatingMomentScreen)
	const isTeacher = isController()
	const isCreating =
		collaborativeCulminatingMomentScreen?.currentStep?.status ===
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING ||
		collaborativeCulminatingMomentScreen?.currentStep?.status ===
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.AWAITING_TEACHER_TO_CONTINUE
	const myTeam = useSelector(getMyTeam)
	const entity = shouldUseContextualDataForTeam || myTeam?.id !== teamId ? { teamId } : undefined
	const doesDataExist = useDoesDataExistForEntity(entity, canvasId)

	return (
		<div
			{...{
				className: classnames(className, 'flex flex-col flex-1'),
				'data-training-target': TRAINING.IDS.CREATIVE_CANVAS_RUBRIC_AND_DATA_EXPLAINED,
			}}>
			<div className="flex gap-2 w-full flex-initial">
				<div
					className="text-white p-2 rounded-t-lg flex gap-2 cursor-pointer flex-auto bg-primary-600"
					onClick={() => setSelectedTab(AVAILABLE_TABS.RUBRIC)}>
					<FaCheck className="text-accent-green self-center" />
					<h4 className="m-0 font-normal">Rubric</h4>
				</div>
				{doesDataExist && (
					<div
						className="text-white p-2 rounded-t-lg flex gap-2 cursor-pointer flex-auto bg-primary-700"
						onClick={() => setSelectedTab(AVAILABLE_TABS.DATA)}>
						<FaStar color="var(--color-secondary)" />
						<h4 className="m-0 font-normal">Data</h4>
					</div>
				)}
			</div>
			<div className="min-h-0 flex-1">
				{selectedTab === AVAILABLE_TABS.RUBRIC || !doesDataExist ? (
					<Rubric
						criteria={criteria}
						hideHeader={true}
						documentId={documentId}
						grades={
							documentId
								? collaborativeCulminatingMomentScreen?.scores?.documents?.[documentId]
								: null
						}
						currentStepStatus={
							collaborativeCulminatingMomentScreen?.currentStep?.status ??
							COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING
						}
						isTeacher={isTeacher}
					/>
				) : null}
				{selectedTab === AVAILABLE_TABS.DATA && doesDataExist ? (
					<DataDisplay
						allowVote={isCreating && myTeam?.id === teamId}
						className="size-full"
						smallScreenSize={'1000px'}
						canvasId={canvasId}
						hideHeader={true}
						mediaModalOverrides={mediaModalOverrides}
						entity={entity}
					/>
				) : null}
			</div>
		</div>
	)
}
