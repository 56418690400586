import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { animated, useTransition, config } from '@react-spring/web'

import { getPointEventsToDisplay, removePointEvent } from '../store/stores/points'
import { BONUS_YELLOW, CORRECT_QUESTION_PURPLE, POINTS_PINGER_Z_INDEX } from '../constants/styles'
import { random } from '../utility/functions'

import type { PointEvent } from '../store/stores/points'
import type { ReturnType } from '../types/utilityTypes'

const REMOVE_POINT_WAIT = 4500

const PointsContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
`

const PointDisplayContainer = animated(styled.div`
	position: absolute;
	font-size: 30px;
	z-index: ${POINTS_PINGER_Z_INDEX};
	color: ${({ $positive }) => ($positive ? 'greenyellow' : 'red')};
`)

export function usePointTransition(
	eventArray: $ReadOnlyArray<{ id: string }>,
	removeEvent: (item: { id: string }) => void
): ReturnType<typeof useTransition> {
	return useTransition(eventArray, {
		from: { opacity: 0, transform: 'rotate(0turn) translateY(0px)' },
		enter: {
			opacity: 1,
			transform: `rotate(${getRandomRotation()}turn) translateY(${random(-40, -20)}px)`,
		},
		keys: item => item.id,
		leave: { opacity: 0 },
		onRest: (_, _1, item) => setTimeout(() => removeEvent(item), REMOVE_POINT_WAIT),
		config: (item, state) =>
			state === 'leave' ? config.default : { ...config.wobbly, tension: 150 },
	})
}

export default function PointsPinger(): React$Node {
	const pointEvents: PointEvent[] = useSelector(getPointEventsToDisplay)
	const dispatch = useDispatch()
	const transitions = usePointTransition(pointEvents, item => {
		dispatch(removePointEvent(item.id))
	})
	return (
		<PointsContainer>
			{transitions((props, pointEvent) => (
				<PointDisplayContainer
					style={{ top: pointEvent.location.y, left: pointEvent.location.x, ...props }}
					$positive={pointEvent.amount >= 0}
					key={pointEvent.id}>
					<b>
						{pointEvent.amount >= 0 ? '+' : '-'}
						{Math.abs(pointEvent.amount)}
					</b>
					{pointEvent.boostAmount && (
						<Boost boost>+{String(pointEvent.boostAmount) + ' BOOST!'}</Boost>
					)}
				</PointDisplayContainer>
			))}
		</PointsContainer>
	)
}

const Boost = styled.div`
	font-size: 15px;
	z-index: 10;
	color: ${({ boost }) => (boost ? BONUS_YELLOW : CORRECT_QUESTION_PURPLE)};
	white-space: nowrap;
`

/**
 * Gets a random rotation for the point display. Returns a random number within the ranges [-0.02, -0.01] or [0.01, 0.02]
 */
function getRandomRotation() {
	if (Math.random() > 0.5) {
		return random(0.01, 0.02, { decimal: true })
	} else {
		return random(-0.02, -0.01, { decimal: true })
	}
}
