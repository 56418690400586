import React from 'react'
import styled from 'styled-components/macro'

/**
 * A decorative svg design that appears on the left side of the extender frame when in the horizontal view for a literacy event.
 * @returns {React$Node}
 */
export function DecalExtenderFrame({
	contentRef,
}: {
	contentRef: { current?: HTMLDivElement | null },
}): React$Node {
	const containerWidth = contentRef.current?.clientWidth || 200
	const topLineStart = Math.ceil(containerWidth * 0.8)
	const bottomLineStart = Math.ceil(containerWidth * 0.4)

	return (
		<FillerDiv>
			<AbsoluteDiv>
				<svg viewBox="0 0 10 10">
					<path d="M0 0 L10 10 L0 10 L0 0 Z" fill="#25346C" />
					<path
						d={`M10 13 ${topLineStart} 13 L${topLineStart + 10} 25`}
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
				</svg>
				<div css="flex-grow: 1;width: 10px; background-color: #25346c; z-index: 1;"></div>
				<svg viewBox="0 0 10 10">
					<path d="M0 0 L10 0 L0 10 L0 0 Z" fill="#25346C" />
					<path
						d={`M10 -3 L${bottomLineStart} -3 L${bottomLineStart + 10} -15`}
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
					<circle
						cx={bottomLineStart + 18}
						cy="-6"
						r="5"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
					<circle
						cx={bottomLineStart + 36}
						cy="-6"
						r="5"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
				</svg>
			</AbsoluteDiv>
		</FillerDiv>
	)
}

const FillerDiv = styled.div`
	align-self: stretch;
	position: relative;
	width: 10px;
	svg {
		width: 10px;
		overflow: visible;
	}
`

const AbsoluteDiv = styled.div`
	position: absolute;
	top: -24px;
	display: flex;
	flex-direction: column;
	height: calc(100% + 48px);
`
