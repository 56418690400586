import React from 'react'
import { rgba, APP_WHITE } from '../../constants/styles'
import styled, { css, type CSSRules } from 'styled-components/macro'

// A component that displays the list for multi media content (used in collaborative canvas station and on the sensor screen)
export function ListDisplayForMultiMedia({
	children,
	...props
}: {
	children: React$Node,
}): React$Node {
	return <StyledListContainer {...props}>{children}</StyledListContainer>
}

// Styles used for the list container, which can be used in place of the component if more customization is needed.
export const ListStyles: CSSRules = css`
	padding: 20px;
	margin-top: -5px;
	background: var(--color-primary-darker);
	border-radius: 0px var(--spacing) var(--spacing) var(--spacing);
	height: -webkit-fill-available;
	scrollbar-gutter: stable both-edges;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 95%;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${rgba(APP_WHITE, 0.2)};
	}
`

const StyledListContainer = styled.div`
	${ListStyles}
`

// A component that displays an empty message and is expected to be a direct child for the StyledListContainer component.
export function EmptyList({ children }: { children: React$Node }): React$Node {
	return (
		<div className="text-center rounded text-white h-full flex p-4 flex-col justify-center text-5xl bg-white/20">
			<h2>{children}</h2>
		</div>
	)
}
