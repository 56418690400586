import React from 'react'
import type { Image, Video, Audio } from '@mission.io/mission-toolkit/actions'
import { VideoController } from '../../VideoController'
import classnames from 'classnames'
import { ContentExpander } from './ContentExpander'

/**
 * Media event input is a component for displaying media without text during a literacy event.
 * @param {Image|Video|Audio} props.media the media that will be displayed
 * @param {string} props.title the title of the media
 * @returns {React$Node}
 */
export function MediaEventInput({
	media,
	title,
	controlModal,
	className,
}: {
	media: Image | Video | Audio,
	title?: string,
	className?: string,
	controlModal?: {
		isOpen: boolean,
		setIsOpen: (boolean | (boolean => boolean)) => void,
	},
}): React$Node {
	if (media.type === 'IMAGE') {
		return (
			<div className={classnames(className, 'relative flex-1 overflow-hidden')}>
				{/* Title is purposefully left off of the image media so that an image can be fully seen. */}
				<img alt="Event Alert" className="max-w-full max-h-full inset-center" src={media.url} />
				<ContentExpander
					controlModal={controlModal}
					css="bottom: var(--spacing); right: var(--spacing)">
					<img
						alt="Event Alert"
						className="border-2 border-primary-green rounded-lg max-w-full max-h-full"
						src={media.url}
					/>
				</ContentExpander>
			</div>
		)
	} else if (media.type === 'VIDEO') {
		return (
			<div
				className={classnames(
					className,
					'relative flex flex-col w-full items-center justify-start h-[calc(100%-var(--spacing2x))]'
				)}>
				{title && <Header title={title} />}
				<VideoController
					autoPlay={false}
					css="max-width: 100%; max-height: 100%; flex: 1; overflow: hidden;"
					src={media.url}
				/>
			</div>
		)
	} else if (media.type === 'AUDIO') {
		return (
			<div
				className={classnames(
					className,
					'relative flex flex-col w-full items-center justify-center h-[calc(100%-var(--spacing2x))]'
				)}>
				{title && <Header title={title} />}
				<audio css="width: 100%;" controls>
					<source src={media.url} />
				</audio>
			</div>
		)
	}
}

const Header = ({ title }) => {
	return title ? <h3 css="margin: 0 0 var(--spacing2x) 0; color: inherit;">{title}</h3> : null
}
