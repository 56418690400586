import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
	getActiveAlerts,
	getRobotAction,
	getCurrentRoomIdOfRobot,
} from '../../../../store/selectors/jrPlusState/decks'
import { getRooms, getAllAlerts } from '../../../../store/stores/staticData'
import Messages from './Messages'
import { useShipScreenSize } from '../../hooks'
import 'styled-components/macro'

import Alert from './Alert'

export default function Alerts(): React$Node {
	const alerts = useSelector(getActiveAlerts)
	const alertData = useSelector(getAllAlerts)
	const robotAction = useSelector(getRobotAction)
	const robotRoom = useSelector(getCurrentRoomIdOfRobot)
	const rooms = useSelector(getRooms)

	const [alertRenderOrder, setAlertRenderOrder] = useState(([]: string[]))

	useEffect(() => {
		let alertsToPosition: string[] = alerts.map(alert => alert.id)
		setAlertRenderOrder((currentOrder: string[]) => {
			const alertsPositioned: Set<string> = new Set(currentOrder)
			const newAlertOrder = [...currentOrder]

			alertsToPosition.forEach((alertId: string) => {
				if (alertsPositioned.has(alertId)) {
					// alert already positioned
					alertsPositioned.delete(alertId)
					return
				}
				newAlertOrder.unshift(alertId)
			})

			return newAlertOrder.filter(alertId => !alertsPositioned.has(alertId))
		})
	}, [alerts])

	const pullAlertToFront: (alertId: string) => void = useCallback(
		(alertId: string) => {
			setAlertRenderOrder(alertRenderOrder => {
				if (alertRenderOrder.length && alertRenderOrder[alertRenderOrder.length - 1] === alertId) {
					// do not cause rerender if alert is already in front
					return alertRenderOrder
				}

				const newAlertOrder = alertRenderOrder.filter(currentAlertId => currentAlertId !== alertId)
				newAlertOrder.push(alertId)

				return newAlertOrder
			})
		},
		[setAlertRenderOrder]
	)

	const alertIdOnRobotRoom = alerts.find(alert => alert.roomId === robotRoom)?.id
	useEffect(() => {
		if (alertIdOnRobotRoom) {
			pullAlertToFront(alertIdOnRobotRoom)
		}
	}, [pullAlertToFront, alertIdOnRobotRoom])

	const { width, height, top, left } = useShipScreenSize()

	const alertMap = {}
	alerts.map(alert => (alertMap[alert.id] = alert))

	// order the alerts so we draw the alerts in the correct order
	const orderedAlerts = []
	alertRenderOrder.forEach(alertId => {
		if (alertMap[alertId]) {
			orderedAlerts.push(alertMap[alertId])
		}
	})

	return (
		<div
			css={`
				height: ${height}px;
				width: ${width}px;
				position: absolute;
				top: ${top}px;
				left: ${left}px;
				z-index: calc(var(--z-index-overlay) + 1);
				pointer-events: none;
			`}>
			{orderedAlerts.map((alert, index) => {
				const roomPoint = rooms[alert.roomId].point
				return (
					<Alert
						isActiveAlert={index + 1 === orderedAlerts.length}
						key={alert.id}
						alert={alert}
						point={roomPoint}
						data={alertData[alert.alertKey]}
						action={robotAction && robotAction.alertId === alert.id ? robotAction : null}
						pullAlertToFrontById={pullAlertToFront}
					/>
				)
			})}
			<Messages />
		</div>
	)
}
