// @flow
import React, { useState, useRef, useCallback, useEffect } from 'react'
import 'styled-components/macro'
import GridBackdrop, { CANVAS_HEIGHT, CANVAS_WIDTH, type Cell } from './Grid'
import Upgrades from './assets/upgradeLookup'
import Beam from './Beam'
import { getTheta } from '../../../utility/functions'
import { getDistanceToCell } from '../helpers'
import Target from './Target'
import Rectangle from '../../../utility/rectangle'
import { useSelector } from 'react-redux'
import {
	getMachineIndex,
	getStudentTargets,
	getTargetMap,
} from '../../../store/selectors/jrPlusState/tractorBeam'
import type { OnHitFunctionType } from '../TractorBeam'
import type { StudentTractorBeamTarget } from '@mission.io/mission-toolkit'

type Props = {
	onMiss: () => void,
	onHit: OnHitFunctionType,
}

/**
 * The game which students play to grab targets with a tractor beam
 * @param {() => void} props.onMiss when a target is missed this function is invoked
 * @param {(location: {x: number, y: number}) => void} props.onHit when a target is collected this function is invoked
 */
export default function ShooterGame({ onMiss, onHit }: Props): React$Node {
	const upgrade = useSelector(getMachineIndex)
	const centerRef = useRef<?Element>()
	const [angle, setAngle] = useState(90)
	const [beamLength, setBeamLength] = useState(0)
	const isBeaming = useRef(false)
	const [targets, optimisticUpdate] = useStudentTargets()
	const targetsRef = useRef({})
	const [capturedTarget, setCapturedTarget] = useState()
	const [activeCell, setActiveCell] = useState()
	const targetMap = useSelector(getTargetMap)

	/**
	 * Sets the angle to aim for the blaster.
	 * @param {Event} e
	 */
	const setAim = useCallback(e => {
		if (isBeaming.current) {
			return
		}
		const cursorX = e.clientX
		const cursorY = e.clientY
		if (centerRef.current) {
			const rect = centerRef.current.getBoundingClientRect()
			// $FlowFixMe[prop-missing] x and y do exist in the ClientRect
			const center = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 }

			setAngle(getTheta(cursorX, cursorY, center))
		} else {
			console.error('set aim without reference to center')
		}
	}, [])

	/**
	 * Logic used when a cell is clicked on the grid. Determines the distance that a beam needs to travel to
	 * reach that cell in the canvas.
	 */
	const clickCell = useCallback(
		(e: MouseEvent, cell: Cell) => {
			if (isBeaming.current) {
				return
			}
			setAim(e)

			const startIndex = cell.id.indexOf('[')
			const lastIndex = cell.id.lastIndexOf(']')
			const comma = cell.id.indexOf(',')
			const cellX = parseInt(cell.id.slice(startIndex + 1, comma), 10)
			const cellY = parseInt(cell.id.slice(comma + 1, lastIndex), 10)
			const cellRectangle = {
				width: cell.width,
				height: cell.height,
				x: cell.x,
				y: cell.y,
			}
			const length = getDistanceToCell({
				id: cell.id,
				coordinates: [cellX, cellY],
				rectangle: cellRectangle,
			})
			setActiveCell({ id: cell.id, ...cellRectangle })
			setBeamLength(length)
			isBeaming.current = true
		},
		[setAim]
	)
	// Callback for when the beam is extended to its furthest point
	const onExtended = useCallback(() => {
		if (activeCell && targetsRef.current) {
			for (const targetId in targetsRef.current) {
				const targetBBox = targetsRef.current[targetId]?.getBBox()
				if (!targetBBox) {
					continue
				}
				const targetRect = new Rectangle(targetBBox)
				if (targetRect.intersects(new Rectangle(activeCell))) {
					setCapturedTarget({ id: targetId, at: { x: targetBBox.x, y: targetBBox.y } })
				}
			}
		}
		setBeamLength(0)
	}, [activeCell])
	// Callback for when the beam is retracted back to its nearest point.
	const onRetracted = useCallback(() => {
		if (isBeaming.current) {
			isBeaming.current = false
			if (capturedTarget) {
				const screenPosition = projectRelativePositionOntoScreen(capturedTarget.at, {
					screenWidth: window.innerWidth,
					screenHeight: window.innerHeight,
				})
				onHit({ id: capturedTarget.id, at: screenPosition })
				optimisticUpdate(targets =>
					targets.filter(target => target.instanceId !== capturedTarget.id)
				)
				setCapturedTarget(null)
				return
			} else {
				onMiss()
			}
		}
	}, [onMiss, capturedTarget, onHit, optimisticUpdate])

	const [springConfig, setSpringConfig] = useState()
	const beamConfig = Upgrades[upgrade].beamConfig

	useEffect(() => {
		if (beamLength) {
			const caughtObject = false
			setSpringConfig({
				duration:
					(caughtObject ? beamConfig.withLoad : beamConfig.withoutLoad).speedRatio * beamLength,
			})
		}
	}, [beamLength, beamConfig])

	return (
		<div css="height: 100%; width: 100%" onMouseOver={setAim}>
			<GridBackdrop
				onClickCell={clickCell}
				css="max-height: 100%; max-width: 100%; display: inline-block;"
				height="100%"
				ref={centerRef}>
				{targets.map(target => {
					const id = target.instanceId
					const isCaught = id === capturedTarget?.id
					const fullTargetData = targetMap[target.targetId]
					if (!fullTargetData) {
						return null
					}
					return (
						<Target
							key={id}
							instanceId={id}
							target={fullTargetData}
							ref={el => (targetsRef.current[id] = el)}
							capturedAt={isCaught ? capturedTarget?.at : null}
							retractingSpringConfig={isCaught ? springConfig : null}
						/>
					)
				})}
				<Machine
					beamSpringConfig={springConfig}
					upgrade={upgrade}
					angle={angle}
					beamLength={beamLength}
					onExtended={onExtended}
					onRetracted={onRetracted}
				/>
			</GridBackdrop>
		</div>
	)
}

/**
 * We need to determine the position of a target on the screen in order to call onHit which uses the position to create a point event.
 *
 * In order to determine the screen position of a target we translate the relative position of the target in the svg to the screen width and height.
 * This is possible because the width of the tractor beam svg is the same width as the screen.
 * This would NOT be correct on a teacher station where the canvas svg is smaller than the screen. However, since teachers do not have point events, we chose to not worry about this.
 * @param {{x: number, y: number}} x.y the relative position of the target in the svg
 * @param {{screenWidth: number, screenHeight: number}} screenWidth.screenHeight the width and height of the screen
 * @returns
 */
function projectRelativePositionOntoScreen(
	{ x, y }: { x: number, y: number },
	{ screenWidth, screenHeight }
) {
	const x_percent = x / CANVAS_WIDTH
	const y_percent = y / CANVAS_HEIGHT
	return {
		x: screenWidth * x_percent,
		y: screenHeight * y_percent,
	}
}

/**
 * The machine which rotates and shoots a tractor beam out into the canvas.
 * @param {number} props.upgrade the index in the upgrades array which helps us determine configurations for the tractor beam device
 * @param {number} props.angle the angle where the machine should be pointing out into the canvas.
 */
function Machine({
	upgrade,
	angle,
	beamLength,
	beamSpringConfig,
	onExtended,
	onRetracted,
}: {
	upgrade: number,
	angle: number,
	beamLength: number,
	beamSpringConfig: ?{},
	onExtended: () => void,
	onRetracted: () => void,
}) {
	const TractorBeam = Upgrades[upgrade].tractorBeam
	const offset = Upgrades[upgrade].pointOfRotation
	const beamConfig = Upgrades[upgrade].beamConfig

	const tractorBeamHeight = Upgrades[upgrade].dimensions.height

	return (
		<g
			style={{
				transition: 'transform 0.3s ease',
				transform: `rotate(${90 - angle}deg)`,
				transformOrigin: '960px 540px',
			}}>
			<g transform={`translate(${CANVAS_WIDTH / 2 - offset.x} ${CANVAS_HEIGHT / 2 - offset.y})`}>
				{beamConfig.type === 'LIGHT' ? (
					<>
						<Beam
							targetLength={beamLength - tractorBeamHeight}
							onExtended={onExtended}
							onRetracted={onRetracted}
							width={Upgrades[upgrade].dimensions.width}
							springConfig={beamSpringConfig || {}}
							beamConfig={beamConfig}
						/>
						<TractorBeam style={{ overflow: 'visible' }} />
					</>
				) : (
					<TractorBeam
						targetLength={beamLength}
						onExtended={onExtended}
						onRetracted={onRetracted}
						springConfig={beamSpringConfig || {}}
						style={{ overflow: 'visible' }}
					/>
				)}
			</g>
		</g>
	)
}

type SetStateFunction<T> = ((T => T) | T) => void // the set state function provided from react's useState

/**
 * Fetches targets from the full state for the game
 * @returns {[Array<StudentTractorBeamTarget>, SetStateFunction<Array<StudentTractorBeamTarget>>]} the targets and a way to optimistically update those targets
 */
const useStudentTargets = (): [
	Array<StudentTractorBeamTarget>,
	SetStateFunction<Array<StudentTractorBeamTarget>>
] => {
	const targets = useSelector(getStudentTargets)
	const [localTargets, setLocalTargets] = useState<Array<StudentTractorBeamTarget>>([])
	useEffect(() => {
		if (targets) {
			setLocalTargets(targets)
		}
	}, [targets])
	return [localTargets, setLocalTargets]
}
