import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import Wifi from '../../stations/Communication/Wifi/WifiSymbol'
import { Station } from '../../components/basics'
import BaseStation, { BaseStation as BaseStationClass } from './BaseStation'
import CoordinatePlane from './Coordinates'
import Target from './Target'
import uuid from 'uuid/v4'
import Bullet from './Bullet'
import { doesCollide } from './collision'
import classnames from 'classnames'
import { clamp } from '../../utility/functions'
import {
	getCurrentSignalStrength,
	isCommunicationActive,
	getTargetSignalStrength,
	getStudentTargets,
} from '../../store/selectors/jrPlusState/communication'

import type { PointEvent } from '../../types'
import type { ReactObjRef } from '../../types'
import type { ReduxStore } from '../../store/rootReducer'
import type { Collision } from './collision'

import './Communication.css'

const BULLET_SPEED = 0.2
const BULLET_HEIGHT = 0.05
const BULLET_WIDTH = 0.1
const BULLET_DELAY = 500

const COORDINATE_OFFSET = 25

const DISH_SPEED = 30
const TARGET_HIT_ANIMATION_TIME = 3

const IS_NUMBER_REGEX = /^[0-9]+\.?[0-9]*$/

type Props = {
	active: boolean,
	sendMessage: (type: string, payload: any, pointEvent?: PointEvent) => void,
	signalStrength: number,
	targetSignalStrength: number,
	targets: { angle: number, radius: number, id: string }[],
}

type State = {
	prevAngle: string,
	angle: string,
	canvasWidth: number,
	canvasHeight: number,
	baseHeight: number,
	dishAngle: number,
	dishMoving: boolean,
	waitingToFire: ?TimeoutID,
	radialOffset: number,
	targetHits: Set<string>,
	bullets: Array<{
		angle: number,
		id: string,
		hits: number,
	}>,
}

class Communication extends Component<Props, State> {
	canvasRef: ReactObjRef<'div'>
	baseStationRef: ReactObjRef<typeof BaseStationClass>
	baseRef: ReactObjRef<'div'>
	targetRefs: { [targetId: string]: Target }

	constructor(props: Props) {
		super(props)

		this.state = {
			prevAngle: '0',
			angle: '0',
			canvasWidth: 100,
			canvasHeight: 100,
			baseHeight: 0,
			dishAngle: 0,
			dishMoving: false,
			radialOffset: 0,
			waitingToFire: null,
			targetHits: new Set(),
			bullets: [],
		}

		this.canvasRef = React.createRef()
		this.baseStationRef = React.createRef()
		this.baseRef = React.createRef()
		this.targetRefs = {}
	}

	addBullet = (angle: number) => {
		const id = uuid()
		this.setState({
			bullets: [
				...this.state.bullets,
				{
					id,
					angle,
					hits: 0,
				},
			],
		})
	}

	fireBullet = (angle: number) => {
		let timeoutId: TimeoutID = setTimeout(() => {
			this.addBullet(angle)
			if (this.state.waitingToFire === timeoutId) {
				this.setState({ waitingToFire: null })
			}
		}, BULLET_DELAY)
		this.setState({ waitingToFire: timeoutId })
	}

	removeBullet = (id: string) => {
		const newBullets = []
		let bulletToRemove
		this.state.bullets.forEach(bullet => {
			if (bullet.id !== id) {
				newBullets.push(bullet)
			} else {
				bulletToRemove = bullet
			}
		})
		if (bulletToRemove && bulletToRemove.hits === 0) {
			this.props.sendMessage('COMMUNICATION_PLUS_HIT_MESSAGE')
		}
		this.setState({ bullets: newBullets })
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions)

		this.updateDimensions()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions)
	}

	updateDimensions = () => {
		this.setState({
			canvasWidth: (this.canvasRef.current && this.canvasRef.current.clientWidth) || 100,
			canvasHeight: (this.canvasRef.current && this.canvasRef.current.clientHeight) || 100,
			baseHeight: (this.baseRef.current && this.baseRef.current.clientHeight) || 100,
			radialOffset:
				(this.baseStationRef.current && this.baseStationRef.current.getRadialOffset()) || 0,
		})
	}

	render() {
		const { signalStrength, targetSignalStrength, targets, active } = this.props
		const {
			canvasHeight,
			canvasWidth,
			baseHeight,
			bullets,
			dishAngle,
			radialOffset,
			targetHits,
		} = this.state
		const radius = Math.min(
			canvasHeight - baseHeight - COORDINATE_OFFSET - this.getCoordinateOffset(),
			canvasWidth / 2
		)
		const planeOffset = baseHeight + this.getCoordinateOffset()

		return (
			<Station className="CommunicationPlus">
				<div className={'communication-board'} ref={this.canvasRef}>
					<div
						className="base-station-wrapper"
						style={{
							bottom: baseHeight + 'px',
						}}>
						<BaseStation
							angle={dishAngle}
							bottomOffset={baseHeight}
							baseStationRef={this.baseStationRef}
							whenLoaded={this.updateDimensions}
							onFinishRelocating={() => {
								this.fireBullet(dishAngle)
								this.setState({ dishMoving: false })
							}}
							dishSpeed={DISH_SPEED}
						/>
					</div>
					<CoordinatePlane radius={radius} midY={planeOffset} />
					<div className="base">
						<div className="base-upper" />
						<div className="base-lower" ref={this.baseRef}>
							<div
								className={classnames('button', this.canShoot() && 'can-shoot')}
								onClick={this.send}>
								<div>Send</div>
							</div>
							<input
								type="text"
								className="angle-input"
								onFocus={() => this.setState(state => ({ angle: '', prevAngle: state.angle }))}
								onBlur={() => {
									if (this.state.angle === '') {
										this.setState({ angle: this.state.prevAngle })
									}
								}}
								onKeyDown={e => {
									if (e.key === 'Enter') this.send()
								}}
								onChange={(event: SyntheticKeyboardEvent<HTMLInputElement>) => {
									let nextAngle: string = this.state.angle
									if (IS_NUMBER_REGEX.test(event.currentTarget.value)) {
										nextAngle = event.currentTarget.value
									} else if (event.currentTarget.value === '') {
										nextAngle = '0'
									}
									this.setState({
										angle: String(clamp(Number(nextAngle || 0), 0, 180)),
									})
								}}
								value={this.state.angle}
								placeholder={this.state.prevAngle}
							/>
						</div>
						{bullets.map(bullet => {
							return (
								<Bullet
									key={bullet.id}
									angle={toRadians(bullet.angle)}
									speed={BULLET_SPEED}
									width={BULLET_WIDTH}
									height={BULLET_HEIGHT}
									radialOffset={radialOffset}
									canvasWidth={canvasWidth}
									canvasHeight={canvasHeight - planeOffset}
									bottomOffset={planeOffset}
									id={bullet.id}
									onFinish={() => this.removeBullet(bullet.id)}
									onFrame={this.checkCollision}
								/>
							)
						})}
						{active &&
							targets.map(target => {
								return (
									<Target
										key={target.id}
										ref={ref =>
											ref ? (this.targetRefs[target.id] = ref) : delete this.targetRefs[target.id]
										}
										angle={toRadians(target.angle)}
										radius={target.radius}
										radialOffset={radialOffset}
										canvasWidth={canvasWidth}
										canvasHeight={canvasHeight - planeOffset}
										wasHit={targetHits.has(target.id)}
										bottomOffset={planeOffset}
										animationHitStyle={`signalReceived ${TARGET_HIT_ANIMATION_TIME}s linear 0s 1 forwards`}
									/>
								)
							})}
						<Wifi
							targetReception={targetSignalStrength || 1}
							currentReception={signalStrength}
							className="communication-wifi"
						/>
					</div>
				</div>
			</Station>
		)
	}

	getCoordinateOffset = () => {
		return (this.baseStationRef.current && this.baseStationRef.current.getCoordinateOffset()) || 0
	}

	send = () => {
		if (this.canShoot()) {
			if (Number(this.state.angle) === this.state.dishAngle) {
				this.fireBullet(this.state.dishAngle)
			} else {
				this.setState({
					dishAngle: this.state.angle !== '' ? Number(this.state.angle) : 0,
					dishMoving: true,
				})
			}
		}
	}

	onHit = (targetId: string, [x, y]: [number, number]) => {
		setTimeout(() => {
			const newX: number = x
			const newY: number = y
			this.props.sendMessage('COMMUNICATION_PLUS_HIT_MESSAGE', targetId, {
				location: {
					x: newX,
					y: newY,
				},
			})
		}, TARGET_HIT_ANIMATION_TIME * 1000)
	}

	checkCollision = (bulletId: string, bulletCollision: Collision) => {
		for (let key in this.targetRefs) {
			if (this.state.targetHits.has(key)) {
				continue
			}

			const target = this.targetRefs[key]

			if (doesCollide(bulletCollision, target.getCollision())) {
				// $FlowFixMe[incompatible-use]
				// $FlowFixMe[prop-missing]
				this.onHit(key, [target.imgRef.current.x, target.imgRef.current.y])
				const updatedBulletArray = []
				this.state.bullets.forEach(bullet => {
					if (bullet.id !== bulletId) {
						updatedBulletArray.push(bullet)
					} else {
						updatedBulletArray.push({ ...bullet, hits: bullet.hits + 1 })
					}
				})
				this.setState({
					bullets: updatedBulletArray,
					targetHits: new Set([...this.state.targetHits, key]),
				})
			}
		}
	}

	canShoot = (): boolean => {
		return this.state.bullets.length === 0 && !this.state.dishMoving && !this.state.waitingToFire
	}
}

function toRadians(degrees: number): number {
	return (degrees * Math.PI) / 180
}

const mapStateToProps = (state: ReduxStore) => {
	const targets = getStudentTargets(state)
	return {
		active: isCommunicationActive(state),
		signalStrength: getCurrentSignalStrength(state),
		targetSignalStrength: getTargetSignalStrength(state),
		targets: Object.keys(targets).map((id: string) => {
			return { ...targets[id], id }
		}),
	}
}

const mapDispatchToProps = {
	sendMessage,
}

export default (connect(mapStateToProps, mapDispatchToProps)(Communication): (
	props: $Shape<{||}>
) => React$Node)
