import React from 'react'
import { ListDisplayForMultiMedia, EmptyList } from '../../CollectedData/Container'
import { FaStar } from 'react-icons/fa'
import { useContextualData } from '../../../store/selectors/selectorHooks'
import { useMaps } from '../../../store/stores/staticData'
import { ContextualDataCard } from '../../ContextualData/ContextualDataCard'
import type { MediaModalOverrides } from '../../ContextualData/ContextualDataCard'
import type { ContextualDataEntity } from '../../../store/selectors/selectorHooks'

/**
 * A hook that returns whether data exists for an entity using the given canvas.
 * @param {ContextualDataEntity} entity an entity using a canvas (a team id or student id)
 * @param {string} canvasId the creative canvas id
 * @returns {boolean} whether data exists to display to the user.
 */
export function useDoesDataExistForEntity(
	entity: void | ContextualDataEntity,
	canvasId: string
): boolean {
	const maps = useMaps()
	const contextualData = useContextualData(
		{
			type: 'CREATIVE_CANVAS',
			canvasActionId: canvasId,
			maps: maps,
		},
		entity
	)
	return contextualData.length !== 0
}

/**
 * Displays the contextual data for a creative canvas.
 *
 * @param props.entity the contextual data shown will include only the data for this entity. If not provided, uses default entity as defined in `useContextualData`.
 */
export function DataDisplay({
	allowVote,
	className,
	canvasId,
	hideHeader,
	smallScreenSize, // the max size of the screen before the data is compacted into a more space efficient view
	mediaModalOverrides,
	entity,
}: {
	allowVote?: boolean,
	className?: string,
	canvasId: string,
	hideHeader?: boolean,
	smallScreenSize?: string,
	mediaModalOverrides?: ?MediaModalOverrides,
	entity?: ContextualDataEntity,
}): React$Node {
	const maps = useMaps()

	const contextualData = useContextualData(
		{
			type: 'CREATIVE_CANVAS',
			canvasActionId: canvasId,
			maps: maps,
		},
		entity
	)

	return (
		<div className={className}>
			{!hideHeader ? (
				<div className="flex gap-1 p-2 w-1/2 bg-primary-700 rounded-t-lg">
					<FaStar color="var(--color-secondary)" />
					<h4 className="m-0 font-normal">Data</h4>
				</div>
			) : null}
			<ListDisplayForMultiMedia className="[&&]:p-2 [&&]:w-full space-y-4">
				{contextualData.length > 0 ? (
					contextualData.map((datum, index) => (
						<ContextualDataCard
							{...{
								allowVote,
								lookup: {
									type: 'CREATIVE_CANVAS',
									canvasActionId: canvasId,
									maps,
									index,
									entity,
								},
								mediaModalOverrides,
								key: datum.id,
								contextualData: datum,
								smallScreenSize,
							}}
						/>
					))
				) : (
					<EmptyList>No resources available</EmptyList>
				)}
			</ListDisplayForMultiMedia>
		</div>
	)
}
