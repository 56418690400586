import React, { type AbstractComponent, forwardRef, useMemo } from 'react'
import { useSpring, animated } from '@react-spring/web'
import 'styled-components/macro'
import { random } from '../../../utility/functions'
import Rectangle from '../../../utility/rectangle'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './Grid'
import type { TractorBeamTarget } from '@mission.io/mission-toolkit'
import { TRACTOR_BEAM_STATION } from '@mission.io/mission-toolkit/constants'

type Props = {
	target: TractorBeamTarget,
	instanceId: string,
	capturedAt: ?{ x: number, y: number },
	retractingSpringConfig: ?{},
}

const Image = forwardRef(function _Image(
	{
		x,
		y,
		width: _width,
		height: _height,
		target,
		...rest
	}: { x: number, y: number, width?: number, height?: number, target: any },
	ref
) {
	const width = _width || target.size.width
	const height = _height || target.size.height
	return (
		<image
			{...rest}
			css="pointer-events: none;"
			x={x}
			y={y}
			ref={ref}
			width={width}
			height={height}
			xlinkHref={target.image.url}
		/>
	)
})
const AnimatedImage = animated(Image)

function _TargetPreCapture({ target, ...rest }: Props, ref): React$Node {
	const { movement: targetMovement, size: targetSize } = target
	const staticPoint = useMemo(() => {
		if (targetMovement.type !== TRACTOR_BEAM_STATION.MOVEMENT.STATIC) {
			return null
		}
		if (targetMovement.location.type === TRACTOR_BEAM_STATION.LOCATION.RANDOM) {
			return getRandomPointOnCanvasPath(targetSize.width, targetSize.height)
		}
		return targetMovement.location
	}, [targetMovement, targetSize])

	if (staticPoint) {
		return <Image x={staticPoint.x} y={staticPoint.y} ref={ref} target={target} />
	}

	return <MovingTarget {...rest} target={target} ref={ref} />
}

const MovingTarget = forwardRef(function _MovingTarget({ target }: { target: any }, ref) {
	const width = target.size.width
	const height = target.size.height
	const points = useMemo(() => {
		return new Array(24).fill([]).map(() => {
			const point = getRandomPointOnCanvasPath(width, height)
			return { x: point.x, y: point.y }
		})
	}, [width, height])
	const [{ x, y }] = useSpring(() => ({
		from: points[0],
		loop: true,
		to: points,
		delay: random(0, 2000),
		config: { friction: 20, tension: 30 },
	}))
	return <AnimatedImage x={x} y={y} ref={ref} width={width} height={height} target={target} />
})

type CapturedTargetProps = {
	retractTo: { x: number, y: number },
	retractingSpringConfig: ?{},
	capturedAt: { x: number, y: number },
	target: TractorBeamTarget,
}
function _CapturedTarget(props: CapturedTargetProps, ref) {
	const width = props.target.size.width
	const { x, y, size } = useSpring({
		from: { x: props.capturedAt.x, y: props.capturedAt.y, size: width },
		to: {
			x: props.retractTo.x + (props.capturedAt.x > props.retractTo.x ? 1 : -1) * 5,
			y: props.retractTo.y - props.target.size.height / 2,
			size: 10,
		},
		config: props.retractingSpringConfig,
	})

	return <AnimatedImage x={x} y={y} width={size} target={props.target} ref={ref} />
}

const CapturedTarget = (forwardRef(_CapturedTarget): AbstractComponent<
	CapturedTargetProps,
	Element
>)
const TargetPreCapture = (forwardRef(_TargetPreCapture): AbstractComponent<Props, Element>)

function Target(props: Props, ref): React$Node {
	if (props.capturedAt) {
		return (
			<CapturedTarget
				capturedAt={props.capturedAt}
				retractTo={CENTER}
				ref={ref}
				target={props.target}
				retractingSpringConfig={props.retractingSpringConfig}
			/>
		)
	} else {
		return <TargetPreCapture {...props} ref={ref} />
	}
}

export default (forwardRef(Target): AbstractComponent<Props, Element>)

// Constant values and logic used to determine random paths
const CANVAS_RECTS = []
const QUADRANTS = [[], [], [], []]
const rectWidth = Math.ceil(CANVAS_WIDTH / 6)
const rectHeight = Math.ceil(CANVAS_HEIGHT / 4)
const emptyRadiusFromCenter = 200
const CENTER = { x: CANVAS_WIDTH / 2, y: CANVAS_HEIGHT / 2 }
const emptyBoxInCenter = {
	x: CENTER.x - emptyRadiusFromCenter,
	y: CENTER.y - emptyRadiusFromCenter,
	width: 2 * emptyRadiusFromCenter,
	height: 2 * emptyRadiusFromCenter,
}

for (let y = 0; y < CANVAS_HEIGHT; y += rectHeight) {
	for (let x = 0; x < CANVAS_WIDTH; x += rectWidth) {
		const rect = [
			[x, y],
			[x + rectWidth, y],
			[x + rectWidth, y + rectHeight],
			[x, y + rectHeight],
		]
		CANVAS_RECTS.push(rect)
		let quadrant = y >= CENTER.y ? (x < CENTER.x ? 0 : 1) : x < CENTER.x ? 3 : 2
		QUADRANTS[quadrant].push(rect)
	}
}

const getRandomPointOnCanvasPath = (function() {
	let iter = random(0, QUADRANTS.length - 1)
	let inc = Math.random() < 0.5
	let availableRects = [...QUADRANTS[iter]]

	return (width, height) => {
		const randomIndex = random(0, availableRects.length - 1)
		const randomRect = availableRects[randomIndex]
		availableRects.splice(randomIndex, 1)
		if (availableRects.length === 0) {
			if (iter + 1 === QUADRANTS.length) {
				inc = false
			}
			if (iter === 0) {
				inc = true
			}
			iter = inc ? iter + 1 : iter - 1

			availableRects = [...QUADRANTS[iter]]
		}

		let x = random(randomRect[0][0], randomRect[1][0])
		let y = random(randomRect[1][1], randomRect[2][1])
		let innerBox = new Rectangle(emptyBoxInCenter)

		const targetBox = new Rectangle({ y, x, width, height })
		if (targetBox.intersects(innerBox)) {
			if (x < CENTER.x) {
				x = CENTER.x - emptyRadiusFromCenter - width
			} else if (x > CENTER.x) {
				x = CENTER.x + emptyRadiusFromCenter
			}
		}
		return { x, y }
	}
})()
