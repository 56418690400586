import React, { useEffect, useState } from 'react'
import { getIsCreativeCanvasGradingStep } from '../../store/selectors/sharedSelectors'
import { getIssueContent } from './connect/selectors'
import Tabs from '../ClassQuestion/Tabs'
import { Prompt } from './components/Prompt'
import Canvas from './Canvas'
import { useSelector } from 'react-redux'
import 'styled-components/macro'
import { useTeam } from '../../store/selectors/jrPlusState/teams'
import type { MediaModalOverrides } from '../ContextualData/ContextualDataCard.jsx'
import { ReactModal } from '../basics'
import { useDimensions, useScreenDimensions } from '../../utility/hooks.jsx'
import { TAILWIND_SCREEN_SIZE_VALUES } from '../../constants/styles'

/**
 * The creative canvas station where students will be able to analyze data and collaborate to form multi-media solutions to mission issues.
 * @param props.shouldUseContextualDataForTeam if true, the contextual data shown will be the data for the team specified in `props.teamId` instead of the current user
 */
export function TeamLayout({
	canvasId,
	teamId,
	documentId,
	isTeacher = false,
	mediaModalOverrides,
	shouldUseContextualDataForTeam,
}: {
	canvasId: string,
	teamId: string,
	documentId: string,
	isTeacher?: boolean,
	mediaModalOverrides?: ?MediaModalOverrides,
	shouldUseContextualDataForTeam?: boolean,
}): React$Node {
	const isGrading = useSelector(getIsCreativeCanvasGradingStep) ?? false
	const { width: screenWidth, height: screenHeight } = useScreenDimensions()
	const isReadOnly = isGrading || (screenWidth < TAILWIND_SCREEN_SIZE_VALUES.md && isTeacher)
	const team = useTeam(teamId)

	const { prompt } = useSelector(getIssueContent({ canvasId, teamId })) || {}

	const [ref, { width: _containerWidth, height: _containerHeight }] = useDimensions()

	const containerWidth = _containerWidth ?? 700
	const containerHeight = _containerHeight ?? 400

	const containerRatio = containerWidth / containerHeight

	const percentageForCanvas = (() => {
		if (containerRatio < 1.5) {
			return 0.7
		} else if (containerRatio < 2) {
			return 0.6
		} else if (containerRatio < 2.5) {
			return 0.48
		} else {
			return 0.4
		}
	})()
	const [expanded, setExpanded] = useState(false)
	// There is already custom full screen logic for teacher being performed in a parent component for the teacher station
	const allowFullScreen = !isTeacher && !isReadOnly
	// reset expanded variable when/if we no longer allow the canvas to be expanded
	useEffect(() => {
		if (!allowFullScreen && expanded) {
			setExpanded(false)
		}
	}, [allowFullScreen, expanded])

	if (!canvasId) return null

	return (
		<div
			ref={ref}
			className="min-h-0 h-full px-4 mb-6 flex flex-col md:grid gap-4 md:grid-rows-[auto,1fr]"
			style={{
				gridTemplateColumns: `${percentageForCanvas * 100}% 1fr`,
			}}>
			<div className="flex flex-col gap-4 md:col-start-2">
				{!isTeacher && isGrading && (
					<div className="relative p-2 bg-white/20 rounded-2xl">
						<h2 className="text-center">{team?.displayName} team</h2>
					</div>
				)}
				<Prompt content={prompt} />
			</div>
			<div className="justify-self-center w-full md:row-start-1 md:row-span-2 relative">
				{!expanded && (
					<Canvas
						key={isReadOnly ? 'read-only' : 'allow-write'}
						documentId={documentId}
						isTeacher={isTeacher}
						readOnly={isReadOnly}
						propsForFullScreen={
							allowFullScreen
								? {
										expanded,
										setExpanded,
								  }
								: {}
						}
					/>
				)}
			</div>
			<Tabs
				className="md:row-start-2 md:col-start-2 max-h-full overflow-hidden"
				canvasId={canvasId}
				documentId={documentId}
				teamId={teamId}
				mediaModalOverrides={mediaModalOverrides}
				shouldUseContextualDataForTeam={shouldUseContextualDataForTeam || isTeacher}
			/>

			<ReactModal
				className="mx-auto relative top-1/2 -translate-y-1/2"
				style={(() => {
					let width
					if (0.75 * screenWidth > screenHeight) {
						width = screenHeight * (4 / 3)
					} else {
						width = screenWidth
					}
					return { content: { width } }
				})()}
				overlayClassName="bg-black/50 fixed inset-0 z-10"
				isOpen={expanded}
				onClose={() => setExpanded(false)}>
				<div className="max-h-full w-full my-auto relative">
					<Canvas
						key={isReadOnly ? 'read-only' : 'allow-write'}
						documentId={documentId}
						isTeacher={isTeacher}
						readOnly={isReadOnly}
						propsForFullScreen={
							allowFullScreen
								? {
										expanded,
										setExpanded,
								  }
								: {}
						}
					/>
				</div>
			</ReactModal>
		</div>
	)
}
